import React, { Component } from "react";

import UserService from "../services/user.service";

import Lottie from 'react-lottie'
import animationData from '../lotties/lf30_editor_eaxnGz.json'

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );

    document.body.style.backgroundColor = "#EBEAF7"
  }
  lottieClicked() {
    this.props.history.push("/login");
    window.location.reload();
  }

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
    };

    return (
      <div className="container">
        <header className="jumbotron" style={{ backgroundColor: "#EBEAF7", textAlign: "center", color: "#030066", marginTop: 10, fontFamily: 'Futura' }} >
          <h1>Welcome to Elektra Health's New User Profile Page!</h1>
        </header>
        <div className='flexbox' style={{}}>
          <div style={{ display: 'flex', fontFamily: 'Futura', color: "#030066", flexDirection: 'column', alignContent: 'center', justifyContent: 'space-between' }}>
            <header className="jumbotron" style={{ marginRight: 20, borderRadius: '10px', backgroundColor: "#BBB0E8", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', color: "#030066", marginTop: 10, fontFamily: 'Futura' }} >
              <h3>Here you can sign up to be an Elektra Member and view your private profile.</h3>
            </header>
            <header className="jumbotron" style={{ marginRight: 20, borderRadius: '10px', backgroundColor: "#f7f7f7", boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', color: "#030066", marginTop: 10, fontFamily: 'Futura' }} >
              <h3>Join the Elektra Community for exclusive content catered to you.</h3>
            </header>
          </div>
          <div className='lottie'>
            <Lottie
              options={defaultOptions}
              style={{
                marginBottom: 10,
                backgroundColor: '#030066',
                borderRadius: '10px',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                cursor: 'default'
              }}
              height={'100%'}
              width={'100%'}
              isClickToPauseDisabled={true}
            />
          </div>
        </div>


      </div >
    );
  }
}
