import React, { Component } from "react";
import AuthService from "../services/auth.service";

export default class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: AuthService.getCurrentUser()
    };
  }
  componentDidMount() {
    document.body.style.backgroundColor = "#EBEAF7"
  }
  render() {
    const { currentUser } = this.state;

    if (currentUser == null) {
      this.props.history.push("/");
      window.location.reload();
    }

    return (
      <div className="container">
        <header className="jumbotron" style={{ backgroundColor: '#536DB7', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
          <h3 style={{ color: '#f7f7f7' }}>
            Hi! <strong>{currentUser.username}</strong>  This is your private profile.
          </h3>
        </header>
        <header className="jumbotron" style={{ color: '#030066', backgroundColor: '#BBB0E8', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
          <p>
            <strong>Token:</strong>{" "}
            {currentUser.accessToken.substring(0, 20)} ...{" "}
            {currentUser.accessToken.substr(currentUser.accessToken.length - 20)}
          </p>
          <p>
            <strong>Id:</strong>{" "}
            {currentUser.id}
          </p>
          <p>
            <strong>Email:</strong>{" "}
            {currentUser.email}
          </p>
          <strong>Authorities:</strong>
          <ul>
            {currentUser.roles &&
              currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
          </ul>

        </header>

      </div>
    );
  }
}
