import React, { Component } from "react";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

export default class BoardUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getUserBoard().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });
      }
    );

    document.body.style.backgroundColor = "#EBEAF7"

  }

  render() {

    if (AuthService.getCurrentUser() == null) {
      this.props.history.push("/");
      window.location.reload();
    }

    return (
      <div className="container">
        <header className="jumbotron" style={{
          backgroundColor: '#f7f7f7', color: '#030066', borderRadius: '10px',
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        }}>
          <h3>{this.state.content}</h3>
          <h3> Hi! Here we will post user specific content only Elektra members can see.</h3>
        </header>
      </div>
    );
  }
}
